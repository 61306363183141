@use "../../../themes/global.scss" as Globals;

.commonCustomModal {
  width: auto;
  background-color: Globals.$backgroundMain;
}

.closeButton {
  cursor: pointer;
  position: absolute;
  top: 12px;
  right: 20px;
  font-size: 20px;
  color: Globals.$textBaseColor;
  z-index: 999;
}



