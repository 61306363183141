@use "../../../../themes/global.scss" as Globals;

.dashboard {
    width: 100%;
    height: 100%;
    padding: 18px 18px;
}

.section-title {
    transition: 0.25s all;
    color: Globals.$textBaseColor;
    text-align: left;
    font-weight: 500;
    font-size: 20px;
    padding-bottom: 12px;
}