@use "../../../themes/global.scss" as Globals;

.addModal {
  transition: all 0.25s;

  &.blurred {
    background-color: rgba(255, 255, 255, 0.25);
    border-radius: 8px !important;
    padding: 0px;
  }

  .containerModal {
    transition: all 0.25s;
    padding: 16px;
    z-index: 0;
  }
}

@media screen and (max-width: 768px) {
  .containerModal {
     padding: 12px 6px !important
  }
}


.closeButton {
  cursor: pointer;
  position: absolute;
  top: 12px;
  right: 20px;
  font-size: 20px;
  color: Globals.$textBaseColor;
  z-index: 999;
}

.closeConfirm {
  position: absolute;
  width: 300px;
  height: 140px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color: Globals.$backgroundMain;
  border: 1px solid black;
  border-radius: 8px;
  z-index: 1;

  .message {
    font-size: 14px;
  }
}

.closeConfirmButtons {
  padding-left: 24px;
  padding-right: 24px;
  font-size: 14px;
  margin: 0 4px 0 4px;
}

.containerProduct {
  width: 56%;
}

.productDetail {
  background-color: Globals.$mainColor;
  width: 100%;
  height: max-content;
  display: flex;
  padding: 4px;
  border-radius: 4px;

  .brand {
    padding-top: 2px;
    font-size: 14px;
  }

  .name {
    font-size: 16px;
    font-weight: 500;
  }

  .sku {
    font-size: 13px;
  }
}

.labelModal {
  font-size: 14px !important;
  margin-bottom: 2px;
}

.blurCron {
  -webkit-filter: blur(5px);
  -moz-filter: blur(5px);
  -o-filter: blur(5px);
  -ms-filter: blur(5px);
  filter: blur(5px);
  user-select: none;
  pointer-events: none;
}


