@use "../../../themes/global.scss" as Globals;

.rowItemWrapper {
  background-color: Globals.$mainColor;
  border-radius: 6px;
  margin-top: 6px;
  transition: 0.25s all;

  &:hover {
    background-color: Globals.$hoverColor;
  }
}

.rowItem {
  width: calc(100% - 6.8vw);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 1px 6px 1px 12px;
  color: Globals.$textBaseColor;
  font-size: 0.75vw;

  .blurred {
    -webkit-filter: blur(5px);
    -moz-filter: blur(5px);
    -o-filter: blur(5px);
    -ms-filter: blur(5px);
    filter: blur(5px);
    user-select: none;
    pointer-events: none;
  }
}

.checkboxItem {
  border: 1px solid #6b6b6b;
}

.image {
  width: 40px;
  height: 40px;
  margin-left: 4px;
  margin-right: 4px;
  //con checkbox
  //margin-left: 16px;
  border-radius: 6px;
}

.infoRow {
  width: calc(100% - 14.1vw);
}

.listingWrapper {
  display: flex;
  flex-wrap: wrap;
}

.listing {
  display: inline;
  padding: 1px 4px 1px 4px;
  margin: 1px;
  background-color: #72721a;
  color: #c7c769;
  border-radius: 10px;
  font-weight: 500;
}

.options {
  display: flex;
  width: max-content;
  position: absolute;
  right: 12px;

  div {
    padding: 2px 8px;
    margin: 1px;
    border-radius: 16px;
    font-size: 0.80vw;
    font-weight: lighter;
  }
}

.flagIcon {
  font-size: 14px;
}

@media screen and (min-width: 1600px) {
  .rowItem {
    font-size: 12px;
  }
}

@media screen and (max-width: 768px) {
  .image {
    width: 50px;
    height: 50px;
  }
  .options {
    flex-direction: column;
    top: 0;
    bottom: 0;
    margin: auto 0;
    align-self: center;
  }
  .options div {
    font-size: 4vw;
    margin: 2px;
  }
  .rowItem {
    min-height: 110px;
  }
  .rowInfoContainer {
    width: calc(100% - 30px);
    flex-direction: column;

    span {
      font-size: 14px;
    }
  }

  .titleProduct {
    font-size: 16px !important;
  }
}

@media screen and (max-width: 576px) {
  .image {
    width: 70px;
    height: 70px;
  }
  .options {
    flex-direction: column;
    top: 0;
    bottom: 0;
    margin: auto 0;
    align-self: center;
  }
  .options div {
    font-size: 4.1vw;
    margin: 5px 0px;
  }
  .rowItem {
    min-height: 152px;
  }
  .rowInfoContainer {
    width: calc(100% - 30px);
    flex-direction: column;

    span {
      font-size: 12px;
    }
  }
  .titleProduct {
    font-size: 14px !important;
  }
}
