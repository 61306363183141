.toastCustom {
        background-color: transparent !important;
        padding: 10px !important;
        border-radius: 5px !important;
        position: relative;
        z-index: 0;
        box-shadow: none !important;
}

.Toastify__toast {
        margin-bottom: 2px !important;
        zoom: 0.92 !important;
}

.Toastify__toast-body {
  background-color: black !important;
  border-radius: 20px;
}

.toastProgressCustom {
  visibility: hidden;
}

.imgToastLogo1 {
  width: 40px;
}

.imgToastContainer {
    width: 18px;
    position: relative;
    bottom: 16px;
}

.toastMessage {
    font-family: 'DM Sans', serif;
    font-weight: 500;
    font-size: 14px;
    color: white;
}

@-webkit-keyframes toastMessageOpen {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes toastMessageOpen {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.toastContentText {
    overflow: hidden;
    opacity: 0;
    -webkit-animation: toastMessageOpen 0.7s both 0.6s;
    animation: toastMessageOpen 0.7s both 0.6s;
}

@-webkit-keyframes open-in-content {
  0% {
    opacity: 0 !important;
    width: 38px !important;
  }
  100% {
    opacity: 1 !important;
    width: 250px !important;
  }
}
@keyframes open-in-content {
  0% {
    width: 0px;
  }
  100% {
    width: 250px;
  }
}

.slide-in-elliptic-bottom-fwd {
	-webkit-animation: slide-in-elliptic-bottom-fwd 0.7s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-in-elliptic-bottom-fwd 0.7s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

 @-webkit-keyframes slide-in-elliptic-bottom-fwd {
    0% {
      -webkit-transform: translateY(1000px) rotateX(30deg) scale(0);
              transform: translateY(1000px) rotateX(30deg) scale(0);
      -webkit-transform-origin: 50% 100%;
              transform-origin: 50% 100%;
      opacity: 0;
    }
    100% {
      -webkit-transform: translateY(0) rotateX(0) scale(1);
              transform: translateY(0) rotateX(0) scale(1);
      -webkit-transform-origin: 50% -1400px;
              transform-origin: 50% -1400px;
      opacity: 1;
    }
  }
  @keyframes slide-in-elliptic-bottom-fwd {
    0% {
      -webkit-transform: translateY(1000px) rotateX(30deg) scale(0);
              transform: translateY(1000px) rotateX(30deg) scale(0);
      -webkit-transform-origin: 50% 100%;
              transform-origin: 50% 100%;
      opacity: 0;
    }
    100% {
      -webkit-transform: translateY(0) rotateX(0) scale(1);
              transform: translateY(0) rotateX(0) scale(1);
      -webkit-transform-origin: 50% -1400px;
              transform-origin: 50% -1400px;
      opacity: 1;
    }
  }
  
.scale-out-center {
	-webkit-animation: scale-out-center 0.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
	        animation: scale-out-center 0.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
}

@-webkit-keyframes scale-out-center {
    0% {
      -webkit-transform: scale(1);
              transform: scale(1);
      opacity: 1;
    }
    100% {
      -webkit-transform: scale(0);
              transform: scale(0);
      opacity: 1;
    }
  }
  @keyframes scale-out-center {
    0% {
      -webkit-transform: scale(1);
              transform: scale(1);
      opacity: 1;
    }
    100% {
      -webkit-transform: scale(0);
              transform: scale(0);
      opacity: 1;
    }
  }

  @media screen and (max-width: 768px) {
    .Toastify__toast-container {
      right: 0 !important;
      left: initial !important;
      width: 90w !important;
    }
    .toastProgressCustom {
      top: 4.2rem !important;
      margin-left: 106px !important;
  }
  }