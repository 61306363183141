@use "../../../themes/global.scss" as Globals;

.form-check-input {
    width: 2.2em !important;
    padding: 10px !important;
    transition: box-shadow 0.33s;
    background-color: Globals.$secondaryColor !important;

    &:checked {
        background-color: Globals.$mainColor !important;
        border-color: Globals.$mainColor !important;
        background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba(255,255,255,1)'/></svg>") !important;
    }

    &:focus {
        box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.5) !important;
        background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba(106, 118, 134, 1)'/></svg>") !important;
    }
}