@use "../../../themes/global.scss" as Globals;

.ImageModal {
  max-height: 400px;
  transition: all 0.25s;


  .containerModal {
    padding: 16px;
    z-index: 0;
  }
}

@media screen and (max-width: 768px) {
  .containerModal {
     padding: 12px 6px !important;
  }
  .ImageModalDialog {
    width: 95vw !important;
  }
}

.ImageModalDialog {
  width: 400px;
}

.closeButton {
  cursor: pointer;
  position: absolute;
  top: 12px;
  right: 20px;
  font-size: 20px;
  color: Globals.$textBaseColor;
  z-index: 999;
}



