@use "../../../themes/global.scss" as Globals;

.containerCheck {
  width: 18px;
  height: 18px;
  background-color: transparent;
  border: 1px solid Globals.$mainColor;

  &.active {
    background-color: #484848;
    font-family: "Font Awesome 6 Free", serif;

    &:after {
      content: "\f00c";
      font-size: 14px;
      font-weight: 900;
      line-height: 18px;
      height: 18px;
      width: 18px;
      position: absolute;
      display: block;
    }
  }
}