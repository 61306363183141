@use '../../../themes/global.scss' as Globals;

.sidebar {
  transition: 0.25s all;
  width: 90px;
  height: 100%;
  background-color: Globals.$mainColor;
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media screen and (max-width: 768px) {
  .sidebar {
    transition: 0.25s all;
    width: 100%;
    height: 105px;
    background-color: Globals.$mainColor;
    -webkit-box-shadow: 0px 5px 25px 5px Globals.$hoverColor;
    box-shadow: 0px 5px 25px 5px Globals.$hoverColor;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    padding-bottom: 16px;
  }

  .sidebar-content {
    overflow-x: auto;
    overflow-y: hidden;
    width: 100%;
    padding-left: 18px;
    padding-right: 18px;
    padding-top: 8px;
    padding-bottom: 16px;

    .sidebar-row {
      width: max-content;
      flex-wrap: nowrap;
    }

    &::-webkit-scrollbar {
      height: 10px;
    }
    &::-webkit-scrollbar-track {
      background: Globals.$mainColor;
      border-radius: 30px;
    }
    &::-webkit-scrollbar-thumb {
      background: #969696;
      border-radius: 30px;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: #555;
      border-radius: 30px;
    }
  }
  .sidebarButton {
    margin-top: 0px !important;
    margin: 0px 6px !important;
  }
}

@media screen and (max-width: 576px) {
  .sidebarButton i {
    font-size: 24px !important;
  }
  .sidebar {
    height: 90px !important;
    padding-bottom: 10px !important;
  }
}

.logoContainer {
  padding-top: 6px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .logo {
    width: 46px;
    height: 46px;
    background-image: url('../../../assets/images/logo/transparent-logo.png');
    background-size: cover;
  }

  span {
    font-size: 16px;
    color: white;
  }
}

.sidebarButton {
  margin-top: 8px;
  text-decoration: none;
  width: 56px;
  height: 56px;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: 0.25s all;

  &.disabled {
    color: rgb(60, 60, 60);
    cursor: not-allowed;
  }

  &.wordButton {
    width: max-content;
    color: rgb(195, 195, 195);
    font-weight: 500;
    font-size: 15px;
  }

  i {
    font-size: 30px !important;
    transition: 0.25s all;
    color: rgb(172, 172, 172);

    &.disabled {
      color: rgb(60, 60, 60);
    }
  }

  &:hover {
    background-color: #0dc4a277 !important;

    i {
      color: white;
    }
  }

  &.selected {
    background-color: #00e4bc !important;

    i {
      color: white;
    }
  }

  &:not(:first-child) {
    margin-top: 16px;
  }
}

.disabledSidebarOption {
  color: rgb(120, 120, 120) !important;
  position: absolute;
  font-size: 46px !important;
  margin-left: -14px;
  rotate: 6deg;
  margin-top: -6px;
}
