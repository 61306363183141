@use '../../../../../themes/global.scss' as Globals;

.card {
  width: 100%;
  height: 120px;
  background-color: Globals.$mainColor;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  > * {
    color: white;
  }

  .cardIcon {
    font-size: 36px;
  }

  .cardValue {
    font-weight: 800;
    font-size: 22px;
  }

  span {
    text-align: center;
    font-size: 40px;
    padding: 0 20px;
  }

  div {
    font-weight: 500;
    font-size: 20px;
    justify-content: space-around;

    div:first {
      font-size: 24px;
    }
  }

  .tooltipInfoCard {
    position: absolute;
    right: 0px;
    top: 8px;
    font-size: 20px;
    width: 100%;
    text-align: right;
    cursor: pointer;
  }
}

@media screen and (max-width: 768px) {
  .cardIcon {
    font-size: 32px !important;
  }

  .cardName {
    font-size: 14px !important;
  }

  .cardValue {
    font-size: 18px !important;
  }

  .tooltipInfoCard {
    top: 2px !important;
    padding-right: 6px !important;
    font-size: 16px !important;
  }
}

@media screen and (max-width: 576px) {
  .card {
    height: 100px;
  }

  .cardIcon {
    font-size: 28px !important;
  }

  .cardName {
    font-size: 11px !important;
  }

  .cardValue {
    font-size: 16px !important;
  }
}
