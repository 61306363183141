@use '../../../themes/global.scss' as Globals;

.datePickerContainer {
  background-color: Globals.$mainColor;
  width: min-content;
  height: min-content;
  position: relative;
  display: flex;
  border: none;
  border-radius: 5px;
}

.datePickerInput {
  width: 220px;
  height: 32px;
  transition: 0.25s all;
  margin-left: 0px !important;
  transform: scale(1);
  background-color: Globals.$mainColor;
  color: Globals.$textInputBaseColor;
  border: none;
  border-radius: 5px;

  &.smallPlaceholder {
    font-size: 13px !important;
  }

  &.smallPlaceholder::placeholder {
    font-size: 13px !important;
  }
}

.datePickerArrow {
  background-color: transparent !important;
  box-shadow: none !important;
}

.datePickerCalendar {
  background-color: Globals.$mainColor !important;
  color: Globals.$textInputBaseColor !important;
  .rmdp-month-picker,
  .rmdp-year-picker {
    background-color: Globals.$mainColor;
  }
  .rmdp-header-values {
    color: Globals.$textBaseColor !important;
    font-size: 18px;
    font-weight: 600;
  }
  .rmdp-arrow {
    border-color: Globals.$textBaseColor !important;
  }
  .rmdp-day {
    transition: 0.25s all;
    color: Globals.$textBaseColor !important;
  }
  .rmdp-week-day {
    color: #605bff !important;
  }
  .rmdp-today {
    .sd {
      background-color: transparent !important;
      font-weight: 800;
    }
  }
  &:hover {
    border: none;
    overflow: hidden;
  }
}

.icon-date-picker {
  width: 19px;
  height: 30px;
  position: relative;
  color: white;
  top: 1px;
  left: 0px;
  font-size: 20px;
}

@media screen and (max-width: 768px) {
  .datePickerInput {
    width: 170px !important;
  }
  .searchFilters {
    width: 200px !important;
  }
}

@media screen and (max-width: 576px) {
  .datePickerCalendar {
    zoom: 1.5;
    margin-top: 40px;
  }
  .ep-arrow {
    display: none;
  }
}
