@use "../../themes/global.scss" as Globals;

.commonModal {
  background-color: Globals.$backgroundMain;
}

.commonModalBackdrop {
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  background-color: rgb(255 255 255 / 25%);
  border-radius: 8px !important;
  z-index: 2;
}