@use '../../../themes/global.scss' as Globals;

.header {
  width: 100%;
  height: 80px;
  padding-top: 10px;
  padding-left: 25px;
  padding-right: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.themeButton {
  margin: 0px 16px;
  font-size: 20px;
}

.titleHeader {
  font-size: 24px;
}

.notificationButton {
  margin: 2.5px 5px;
  padding: 5px 10px;
  background-color: grey;
  font-size: 18px;
  color: white;
  border-radius: 25px;
}

.joinUsButton {
  margin: 2.5px 5px;
  padding: 5px 10px;
  background-color: Globals.$mainColor;
  font-size: 18px;
  color: white;
  border-radius: 25px;
}

img.profileButton {
  width: 36.5px;
  height: 36.5px;
  margin: 2.5px 5px;
  font-size: 20px;
  color: white;
  border-radius: 25px;
  cursor: pointer;
}

div.profileButton {
  margin: 2.5px 5px;
  padding: 6px 12px;
  background-color: grey;
  font-size: 20px;
  color: white;
  border-radius: 25px;
  cursor: pointer;
}

.logoutButton {
  margin: 2.5px 5px;
  padding: 4px 8px;
  font-size: 18px;
  color: #c52929;
}

@media screen and (max-width: 768px) {
  .joinUsButton {
    font-size: 10px;
    color: white;
    border-radius: 20px;
  }
  .titleHeader {
    font-size: 15px;
  }
}
