@use "../../../../themes/global.scss" as Globals;

.settings {
  width: 100%;
  height: 100%;
  padding: 18px 18px;
}

.section-title {
  transition: 0.25s all;
  color: Globals.$textBaseColor;
  text-align: left;
  font-weight: 400;
  font-size: 18px;
  padding-bottom: 8px;
}

.text-area-settings {
  width: 240px;
  min-height: 100px;
  max-height: 100px;
  transition: 0.25s all;
  background-color: Globals.$mainColor !important;
  color: Globals.$textInputBaseColor !important;
  border: none !important;
  border-radius: 10px;
}

.paymentMethodSelect {
  height: 48px;
  display: flex;
}

@media screen and (max-width: 576px) {
  .selectCommon {
    height: max-content !important;
  }

  .modal-body { 
    .selectCommon {
      height: 40px !important;
    }
  }

  .section-title {
    font-size: 17px;
  }
}