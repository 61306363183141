@use "../../../themes/global.scss" as Globals;

.multiSelectCommon {
  transition: 0.25s all;
  background-color: Globals.$mainColor !important;
  color: Globals.$textInputBaseColor !important;
  border-width: 1.5px;
  border-color: transparent;
  -webkit-appearance: none;
  appearance: none;
  -moz-appearance: none;
  border-radius: 5px;

  &.smallPlaceholder {
    font-size: 13px !important;
  }
}

.buttonCommonMulti {
  min-width: 70px;
  font-size: medium;
  height: 32px;
  padding-left: 0px;
  padding-right: 24px;
  border-radius: 5px;
  text-align: left;
  transition: 0.25s all;
  background-color: Globals.$mainColor !important;
  color: Globals.$textInputBaseColor !important;
  border: none !important;
  -webkit-appearance: none;
  appearance: none;
  -moz-appearance: none;
  background-image: url("data:image/svg+xml;utf8,<svg fill='white' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 100%;
  background-position-y: center;

  &.smallPlaceholder {
    font-size: 13px !important;
  }
}

.dropdownMultiSelect {
  overflow: auto;
  max-height: 300px;
  background-color: Globals.$mainColor;
  color: Globals.$textInputBaseColor !important;
  margin-left: -9px !important;
  padding: 4px;

  div {
    padding: 4px;
    transition: 0.25s all;
    &:hover {
      background-color: Globals.$hoverColor;
    }
  }
}

