@use "../../../themes/global.scss" as Globals;

.selectCommon {
  min-width: 100px;
  height: 40px;
  padding-left: 12px;
  padding-right: 24px;
  text-align: left;
  transition: 0.25s all;
  background-color: Globals.$mainColor !important;
  color: Globals.$textInputBaseColor !important;
  border-width: 1px;
  border-color: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: url("data:image/svg+xml;utf8,<svg fill='white' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 100%;
  background-position-y: center;

  &.smallPlaceholder {
    font-size: 13px !important;
  }

  &:active, &:disabled {
    border-color: transparent !important;
  }
}

