// VARIABILI COLORI DI DEFAULT

:root {
    --text-base-color: white;
    --text-input-base-color: white;
    --background-color-main: #1A202C;
    --main-color: #364153;
    --secondary-color: rgba(54, 65, 83, 0.42);
    --selected-color: #1A202C;
    --hover-color: #3f4c5e;
    --disabled-color: #2c3542;
}


// Text
$textBaseColor: var(--text-base-color);

// Input Text
$textInputBaseColor: var(--text-input-base-color);

// Background
$backgroundMain: var(--background-color-main);

// Sidebar Related
$mainColor: var(--main-color);

$secondaryColor: var(--secondary-color);

$selectedColor: var(--selected-color);

$hoverColor: var(--hover-color);

$disabledColor: var(--disabled-color);
