@use "./themes/global.scss" as Globals;

.tooltip {
  font-family: 'DM Sans', serif !important;
  --bs-tooltip-font-size: 1rem !important;
}


.marquee {
  height: 20px;
  overflow: hidden;
  position: relative;
}

.marquee:hover span {
  position: absolute;
  width: 100%;
  height: 100%;
  margin: 0;
  text-align: center;
  -moz-transform: translateX(0%);
  -webkit-transform: translateX(0%);
  transform: translateX(0%);
  -moz-animation: scroll-left 2s linear infinite;
  -webkit-animation: scroll-left 2s linear infinite;
  animation: scroll-left 20s linear infinite;
}

@-moz-keyframes scroll-left {
  0% {
    -moz-transform: translateX(0%);
  }
  100% {
    -moz-transform: translateX(-100%);
  }
}

@-webkit-keyframes scroll-left {
  0% {
    -webkit-transform: translateX(0%);
  }
  100% {
    -webkit-transform: translateX(-100%);
  }
}

@keyframes scroll-left {
  0% {
    -moz-transform: translateX(0%);
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
  }
  100% {
    -moz-transform: translateX(-100%);
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
}

.cursor-pointer {
  cursor: pointer;
}

//TextArea and Input related

textarea, input {
  padding: 5px 10px;
  &:disabled {
    background-color: Globals.$disabledColor !important;
    color: whitesmoke;
    cursor: not-allowed;
  }
}

.divider {
  width: 100%;
  height: 1px;
  background-color: Globals.$mainColor;
}

.itemRed {
  font-weight: 600;
  color: red !important;
}

.itemGreen {
  font-weight: 600;
  color: rgb(24, 196, 115) !important;
}

.itemYellow {
  font-weight: 600;
  color: rgb(222, 220, 24) !important;
}

.borderLeftNoRadius {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.borderRightNoRadius {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.listMessage {
  color: Globals.$textBaseColor;
  font-size: 18px;
}

@media screen and (max-width: 576px) {
  .listMessage {
    font-size: 15px !important;
  }
}
