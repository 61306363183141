.content {
    width: 100%;
    height: 100%;
    display: flex;
    padding: 10px 15px 25px 15px;
    overflow: auto;
}

.contentWrap {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

@media screen and (max-width: 768px) {
    .contentWrap {
        height: calc(100% - 105px);
    }
    .content {
        margin-bottom: 16px;
        padding-bottom: 8px !important;
    }
  }