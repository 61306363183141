@use "../../../themes/global.scss" as Globals;


.sizePickerWTB {
    height: 280px;
    padding: 0 0 0 4px;
}

.sizeLabels {
    background-color: Globals.$mainColor;
    border-radius: 8px 8px 6px 6px;
    padding-left: 4px;

    .label {
        padding: 4px;
        font-size: 14.5px;
        font-weight: 500;
        text-align: center;
    }
}

.sizePickerPriceWTB {
    .inputCommon {
        margin: 0 !important
    }
}

.sizeList {
    background-color: Globals.$secondaryColor;
    border-radius: 6px 6px 8px 8px;
    padding-top: 2px;
    padding-left: 4px;
    overflow-y: auto;
    margin-right: -1px;
    height: 100%;

    .item {
        transition: 0.25s all;
        padding: 1px 0px 1px 1px;
        border-top: 1px solid rgba(0, 0, 0, 0.2);
        border-bottom: 1px solid rgba(0, 0, 0, 0.2);

        &:hover {
            background-color: Globals.$mainColor;

            .counter {
                background-color: Globals.$backgroundMain;
            }
        }
    }

    .counter {
        transition: 0.25s all;
        background-color: Globals.$mainColor;
        border-radius: 8px;

        i {
            font-size: 12px;
        }

        span {
            font-weight: 500;
            font-size: 14px;
            font-family: "Open Sans", serif;
        }
    }

    .label {
        white-space: nowrap;
        max-height: fit-content;
        padding: 2px;
        font-size: 13px;
        text-align: center;
    }

    /* width */
    &::-webkit-scrollbar {
        width: 4px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
        background: Globals.$mainColor;
        border-radius: 30px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: #969696;
        border-radius: 30px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background: #555;
        border-radius: 30px;
    }
}

@media screen and (max-width: 768px) {
    .sizePickerWTB {
        height: min-content;
        zoom: 0.9;
    }
    .sizeList {
      height: 180px !important;
    }
  }

.inputPicker {
    width: 20px;
    padding: 0px !important;
    margin: 0px !important;
    text-align: center;
    background-color: transparent;
    color: white;
    border: 0;
    font-weight: 500;
    font-size: 14px;
    font-family: "Open Sans", serif;
    font-weight: bolder;
}