@use "../../../themes/global.scss" as Globals;

.paginationFilters {
    width: 100%;
    color: white;
    position: relative;
    top: 5px;

    Select {
        height: 28px;
        padding-top: 2px;
        background-position-y: 1px;
        min-width: 50px;
    }

    .pagination {
        display: flex;
        justify-content: flex-end;

        li {
            margin: 0px 5px 0px 5px;
            padding: 0px 8px;
            background-color: Globals.$secondaryColor; 
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 5px;

            &.previous>a, &.next>a {
                color: Globals.$textBaseColor !important; 
                font-weight: bold;
                font-size: 18px;
            }

            &.active {
                background-color: Globals.$mainColor; 
                font-weight: bold;
            }
        }

        a {
            text-decoration: none;
        }
    }
}

@media screen and (max-width: 576px) {
    .paginationFilters {
        height: 100%;
        align-items: start !important;
        padding-bottom: 20px !important;

        Select {
            margin-top: 0px;
            height: 36px !important;
            font-size: 14px !important;
            width: 80px;
            text-align: left;
        }
    }

    .itemCounter {
        padding-top: 2px;
        font-size: 15px;
    }
    .pagination {
        height: 36px;
        li {
            padding: 0px 12px !important;
        }
    }
}