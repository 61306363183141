@use "../../../themes/global.scss" as Globals;

// Delete Icon (X)
.clear-icon  {
  margin-top: 0px !important;
}

.optionElement {
  transition: 0.33s all;
  background-color: Globals.$mainColor;

 &:hover {
    background-color: Globals.$selectedColor;
  }
}