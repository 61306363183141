@use "../../../themes/global.scss" as Globals;

.buttonCommon {
  height: 40px;
  padding-left: 36px;
  padding-right: 36px;
  transition: 0.25s all;
  background-color: Globals.$mainColor;
  color: Globals.$textInputBaseColor;
  border: none !important;
  width: max-content;

  &:hover {
    background-color: Globals.$secondaryColor;
    color: Globals.$textBaseColor;
  }
}

@media screen and (max-width: 576px) {
  .buttonCommon i {
      font-size: 12px;
  }   
}
