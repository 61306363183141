@use "./themes/global.scss" as Globals;

@import "~bootstrap/scss/bootstrap";
@import "~@fortawesome/fontawesome-free/scss/fontawesome.scss";
@import "~@fortawesome/fontawesome-free/scss/solid.scss";
@import "~@fortawesome/fontawesome-free/scss/brands.scss";

.App {
  transition: 0.25s all;
  background-color: Globals.$backgroundMain;
  zoom: 1;
}

.color-white {
  transition: 0.25s all;
  color: Globals.$textBaseColor !important;
}

.modal {
  --bs-modal-width: 680px;
  z-index: 3;
}

.modal-body {
  padding: 0 !important;
}

.modal-dialog {
  place-content: center; 
}

.modal-content {
  border-radius: 8px !important;
}

.loginPrompt {
  width: 410px;
  height: 250px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid Globals.$mainColor;
  border-radius: 10px;
  z-index: 1;
  &::before{  
    position: absolute;
    content: "";
    background-image: url('./assets/images/logo/transparent-logo.png');
    background-size: 68px;
    background-repeat: repeat;
    z-index: -1;
    width: 99%;
    height: 99%;
    opacity: 0.12;
    filter: blur(5px);
  }

  .welcomeLoading {
    font-size: 20px;
    text-align: center;
  }

  @media screen and (max-width: 768px) {
    .welcomeLoading {
      font-size: 17px;
    }
  }
}

@media screen and (max-height: 900px) {
  .App {
    zoom: 0.8 !important;
  }
}

@media screen and (max-height: 700px) {
  .App {
    zoom: 0.7 !important;
  }
}