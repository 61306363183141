.sold {
  width: 100%;
  height: 100%;
}


@media screen and (max-width: 768px) {
  .sold {
      height: calc(100% - 105px);
  }
}

@media screen and (max-width: 576px) {
  .sold {
      height: calc(100% - 114px);
  }
}


.soldList, .soldContainer {
  height: calc(100% - 98px);
  overflow-y: auto;
}

/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}