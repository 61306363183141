@use "../../../themes/global.scss" as Globals;

#html5qr-code-full-region {
    border-radius: 8px !important;
    border: 2px solid Globals.$mainColor !important;
    color: white !important;
}

#html5qr-code-full-region__header_message {
    border-top: 1px red !important;
}

.html5-qrcode-element {
    height: 40px;
    padding-left: 36px;
    padding-right: 36px;
    transition: 0.25s all;
    background-color: Globals.$mainColor;
    color: Globals.$textInputBaseColor;
    border: none !important;
    border-radius: 8px;
  
    &:hover {
      background-color: Globals.$secondaryColor;
      color: Globals.$textBaseColor;
    }
}

#html5-qrcode-anchor-scan-type-change {
    display: none !important;
}

#html5qr-code-full-region__scan_region {
    img {
        content: url('./../../../assets/images/icons/scanner/scanner.svg') !important;
        fill: rgb(77, 10, 235);
    }
}

img[alt="Info icon"] {
    display: none !important;
}