@use "../../../themes/global.scss" as Globals;

.rowFilters {
  width: calc(100% - 6.2vw);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 8px 12px 8px 11.5px;
  color: Globals.$textBaseColor;
  text-transform: uppercase;
  font-size: 0.75vw;
}

.optionsFilters {
  display: flex;
  width: max-content;
  position: absolute;
  right: 12px;

  .containerCheck {
    margin-top: 10px;
  }

  div {
    border-radius: 16px;
    font-size: 0.80vw;
  }

  .btn {
    max-width: 46px;
  }
}



@media screen and (min-width: 1600px) {
  .rowFilters {
    font-size: 12px;
  }
}