@use "../../../themes/global.scss" as Globals;

.inputContainer {
  position: relative;
}

.inputCommon {
  transform: none;
  width: 100%;
  height: 40px;
  transition: 0.25s all;
  background-color: Globals.$mainColor !important;
  color: Globals.$textInputBaseColor;
  border-width: 1.5px;
  border-color: Globals.$mainColor;

  &.smallPlaceholder {
    font-size: 13px !important;
  }

  &.smallPlaceholder::placeholder {
    font-size: 13px !important;
  }

  &:focus {
    border-color: Globals.$mainColor !important;
    color: whitesmoke;
  }
}

.inputCommon[id="purPrice"] {
    border-bottom-right-radius: 0px;
    border-top-right-radius: 0px;
}

.inputCommon[id="nationalPurPrice"] {
  border-bottom-right-radius: 0px;
  border-top-right-radius: 0px;
}

.inputCommon[id="internationalPurPrice"] {
  border-bottom-right-radius: 0px;
  border-top-right-radius: 0px;
}

.inputPrice {
  color: white;
  background-color: Globals.$backgroundMain;
  width: 50px;
  border: 1px solid Globals.$mainColor;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;

  &.price {
    width: 45px;
    text-align: center;
  }
}

.inputIcon {
  position: absolute;
  color: white;
  background-color: Globals.$mainColor;
  height: 40px;
  width: 40px;
  top: 0px;
  right: 0px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.priceSelect {
  font-size: 13px;
  font-weight: 500;
  height: 40px !important;
  border-width: 1.5px !important;
  margin-top: -1px;
  text-align: center !important;
  min-width: 45px !important;
  padding-left: 0px !important;
  padding-right: 8px !important;
  background-color: Globals.$secondaryColor !important;
  background-position-y: 6px !important;
  background-position-x: 25px !important;
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;

  option {
    background-color: Globals.$mainColor !important;
  }
}

.inputError {
  display: block;
  text-align: justify-all;
  font-weight: 600;
  margin-top: 4px;
  font-size: 11px;
}

.autoWidth {
  min-width: auto !important;
  width: auto !important;
}

.cronWidth {
  width: 60px !important;
}

.panelTime {
  z-index: 999 !important;
  background-color: Globals.$mainColor !important;
}

.ant-picker-input  {

  input {
    color: Globals.$textInputBaseColor !important;
    font-family: 'DM Sans', serif;
    font-size: 16px !important;
  }

  input::placeholder {
    color: whitesmoke !important;
    font-family: 'DM Sans', serif;
    font-size: 16px !important;
  }

  span {
    color: whitesmoke !important;
    font-family: 'DM Sans', serif;
  }
}

@media screen and (max-width: 576px) {

  .inputPrice {
    height: 40px;
    width: 80px;
  
    &.price {
      width: 7dvb;
      text-align: center;
    }
  }

  .priceSelect {
   width: 100%;
   background-position-y: 5px !important;
   background-position-x: 30px !important;
   font-size: 10px !important;
   padding-left: 5px !important;

   div {
    text-align: left;
    
   }
  
    option {
      background-color: Globals.$mainColor !important;
    }
  }

  .modal-body {
   .priceSelect {
      width: 100%;
      background-position-y: 6px !important;
      background-position-x: 22px !important;
      font-size: 10.5px !important;
      padding-left: 5px !important;
   
      div {
       text-align: left;
       
      }
     
       option {
         background-color: Globals.$mainColor !important;
       }
     }
  }

}
