@use "../../../../../themes/global.scss" as Globals;

.listingItemName {
  font-size: 12.5px;
}

.listingItemRow {
  border-radius: 10px;
  padding: 8px;
  background-color: Globals.$mainColor;
}